

























import Vue from 'vue';

import { ICard } from '@/models/card';

export default Vue.extend({
    name: 'hand',
    props: {
        cards: Array,
        selected: Array,
        readonly: Boolean,
        showInfo: Boolean,
        info: String,
    },
    methods: {
        cardClick(card: ICard) {
            this.$emit('card-click', card);
        },
    },
});
