








import Vue from 'vue';

import Field from './Field.vue';

export default Vue.extend({
    components: {
        Field,
    },
    props: {
        fields: Array,
        data: Object,
    },
});
