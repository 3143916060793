











import Vue from 'vue';

import Spinner from './Spinner.vue';

export interface IButtonAction {
    execute: () => Promise<void>;
}

export default Vue.extend({
    components: { Spinner },
    data() {
        return {
            working: false,
        };
    },
    props: {
        label: String,
        action: Object,
    },
    computed: {
        actionTyped() {
            const r = this.action as IButtonAction;
            return r;
        },
    },
    methods: {
        async buttonClick() {
            this.working = true;
            if (this.actionTyped && this.actionTyped.execute) {
                await this.actionTyped.execute();
            }
            this.working = false;
        },
    },
});
