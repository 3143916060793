














import Vue from 'vue';

import ButtonSpinner from '@/components/ButtonSpinner.vue';
import FormFields from '@/components/form/FormFields.vue';

import { IFormField, FormBuilder } from '@/components/form/form-builder';

import { apiPost } from '@/store/api';

export default Vue.extend({
    components: {
        FormFields,
        ButtonSpinner,
    },
    data() {
        return {
            fields: [] as IFormField[],
            formData: {
                name: '',
                email: '',
                password: '',
                cpw: '',
            },
            info: '',
        };
    },
    mounted() {
        this.fields = this.getFormFields();
    },
    methods: {
        getFormFields() {
            return new FormBuilder()
                .addTextbox('name', 'name')
                .addTextbox('email', 'email')
                .addPassword('password', 'password')
                .addPassword('confirm password', 'cpw')
                .build();
        },
        async registerClick() {
            if (
                !this.formData.name ||
                !this.formData.email ||
                !this.formData.password ||
                !this.formData.cpw
            ) {
                return;
            }

            if (this.formData.password !== this.formData.cpw) {
                return;
            }

            const r = await apiPost('/user/register', {
                name: this.formData.name,
                email: this.formData.email,
                password: this.formData.password,
            });
            if (r.ok) {
                this.info = 'registered successfully!';
            }
        },
    },
});
