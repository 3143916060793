














import Vue from 'vue';

import MenuItem from './MenuItem.vue';

import { IPlayer } from '@/models/player';
import { IMenuItem } from '@/models/menuItem';
import { IPopupData, IPopupResult } from '@/store/popup';

const mainMenu = Vue.extend({
    components: {
        MenuItem,
    },
    data() {
        return {
            showMenu: false,
            items: [] as IMenuItem[],
        };
    },
    mounted() {
        this.items = this.createMenu();
        document.addEventListener('click', this.documentClick);
        document.addEventListener('mouseup', this.documentClick);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.documentClick);
        document.removeEventListener('mouseup', this.documentClick);
    },
    computed: {
        currentPlayer(): IPlayer | undefined {
            return this.$store.getters.currentPlayer;
        },
        isHost(): boolean {
            return this.currentPlayer ? this.currentPlayer.isHost : false;
        },
        gameId(): string {
            return this.$route.params.id;
        },
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        documentClick(e: Event) {
            const x = this.$refs.container as Element;
            if (this.showMenu && x && !x.contains(e.target as any)) {
                this.showMenu = false;
            }
        },
        createMenu() {
            return [
                {
                    id: 0,
                    label: 'Rename Player',
                    hidden: () => false,
                    onClick: () => this.renamePlayerClick(),
                },
                {
                    id: 1,
                    label: 'Manage Game',
                    hidden: () => !this.isHost,
                    onClick: () => {
                        this.$router.push(`/game/${this.gameId}/manage`);
                    },
                },
                {
                    id: 2,
                    label: 'Manage Players',
                    hidden: () => !this.isHost,
                    onClick: () => {
                        this.$router.push(
                            `/game/${this.gameId}/manage/players`,
                        );
                    },
                },
                {
                    id: 3,
                    label: 'Leave Game',
                    hidden: () => false,
                    onClick: async () => {
                        await this.$store.dispatch('hubLeaveGame');
                        await this.$router.push('/');
                    },
                },
                {
                    id: 4,
                    label: 'End Game',
                    hidden: () => !this.isHost,
                    onClick: async () => {
                        const result = await this.$store.dispatch(
                            'popup/confirm',
                            {
                                title: 'Confirm End Game',
                                message:
                                    'Are you sure you want to end this game?',
                            } as IPopupData,
                        );
                        if (result) {
                            await this.$store.dispatch('hubEndGame');
                        }
                    },
                },
            ] as IMenuItem[];
        },
        menuItemClick(item: IMenuItem) {
            item.onClick();
            this.showMenu = false;
        },
        async renamePlayerClick() {
            const result: IPopupResult = await this.$store.dispatch(
                'popup/input',
                {
                    title: 'Rename',
                    label: 'Name:',
                } as IPopupData,
            );
            if (result.result && result.input) {
                this.$store.dispatch('hubRenamePlayer', result.input);
            }
        },
    },
});

export type MainMenuRef = InstanceType<typeof mainMenu>;
export default mainMenu;
