export interface IFormField {
    id: number;
    label: string;
    property?: string;
    type: string;
    readonly: boolean;
    action?: () => Promise<void>;
}

export class FormBuilder {
    private idx = 0;
    private form: IFormField[] = [];

    public addTextbox(
        label: string,
        property: string,
        readonly?: boolean,
    ): FormBuilder {
        this.form.push({
            id: this.idx++,
            label,
            property,
            type: 'text',
            readonly: readonly === true ? true : false,
        });
        return this;
    }

    public addPassword(label: string, property: string): FormBuilder {
        this.form.push({
            id: this.idx++,
            label,
            property,
            type: 'password',
            readonly: false,
        });
        return this;
    }

    public addButton(text: string, action: () => Promise<void>): FormBuilder {
        this.form.push({
            id: this.idx++,
            label: text,
            type: 'button',
            readonly: false,
            action,
        });
        return this;
    }

    public build(): IFormField[] {
        return this.form;
    }
}
