




import Vue from 'vue';
import { IWaitTimer } from '@/models/waitTimer';

export default Vue.extend({
    name: 'countdown',
    data() {
        return {
            active: false,
            id: 0,
            start: 0,
            remaining: 0,
            timer: {} as IWaitTimer,
        };
    },
    computed: {
        idleTimer(): IWaitTimer | null {
            return this.$store.state.hub.idleTimer;
        },
    },
    watch: {
        idleTimer(n: IWaitTimer | null) {
            if (n) {
                this.startIdleTimer(n);
            } else {
                this.cancelIdleTimer();
            }
        },
    },
    methods: {
        startIdleTimer(t: IWaitTimer) {
            if (this.active) {
                this.cancelIdleTimer();
            }
            this.remaining = t.seconds;
            this.start = Date.now();
            this.timer = t;
            this.id = window.setInterval(() => {
                const ms = Date.now() - this.start;
                const secs = Math.round(ms / 1000);
                this.remaining = this.timer.seconds - secs;
                if (secs >= this.timer.seconds) {
                    window.clearInterval(this.id);
                    this.active = false;
                }
            }, 1000);
            this.active = true;
        },
        cancelIdleTimer() {
            if (this.active) {
                window.clearInterval(this.id);
                this.active = false;
            }
        },
    },
});
