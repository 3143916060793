


















import Vue from 'vue';

import { apiPost } from '@/store/api';
import { IUser } from '@/models/user';
import { FormBuilder, IFormField } from '@/components/form/form-builder';
import FormFields from '@/components/form/FormFields.vue';
import ButtonSpinner from '@/components/ButtonSpinner.vue';

export default Vue.extend({
    components: {
        FormFields,
        ButtonSpinner,
    },
    data() {
        return {
            fields: [] as IFormField[],
            formData: {
                name: '',
                email: '',
                test: '',
            },
        };
    },
    mounted() {
        this.setUser();
    },
    computed: {
        loggedIn() {
            return this.$store.getters['user/loggedIn'];
        },
        user(): IUser | undefined {
            return this.$store.state.user.user;
        },
    },
    watch: {
        loggedIn(n: boolean, o: boolean) {
            if (o == true && n == false) {
                this.$router.push('/user/login');
            }
        },
    },
    methods: {
        setUser() {
            if (this.user) {
                this.fields = this.getFormFields();
                this.formData.email = this.user.email;
                this.formData.name = this.user.name;
            }
        },
        getFormFields() {
            return new FormBuilder()
                .addTextbox('email', 'email', true)
                .addButton('change e-mail', this.changeEmailClick)
                .addTextbox('name', 'name')
                .build();
        },
        async verifyClick() {
            const r = await apiPost('/user/resendVerify', {});
            if (r.ok) {
                // x
            }
        },
        async changeEmailClick() {
            // change e-mail
        },
        async saveClick() {
            // save
        },
    },
});
