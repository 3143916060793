








import Vue from 'vue';

import NavHeader from '@/components/NavHeader.vue';
import CluelessPopup, {
    CluelessPopupRef,
} from '@/components/CluelessPopup.vue';

export default Vue.extend({
    name: 'game',
    components: {
        NavHeader,
        CluelessPopup,
    },
    computed: {
        hasCluelessPlayers(): boolean {
            return this.$store.getters.hasCluelessPlayers;
        },
        cluelessPopup(): CluelessPopupRef {
            return this.$refs.cluelessPopup as CluelessPopupRef;
        },
        cluelessDialog(): number {
            return this.$store.state.clueless.dialog;
        },
    },
    watch: {
        hasCluelessPlayers(x: boolean, y: boolean) {
            if (x && !y) {
                this.cluelessPopup.showDialog();
            }
        },
        cluelessDialog(n: number, o: number) {
            if (n > o) {
                this.cluelessPopup.showDialog();
            }
        },
    },
});
