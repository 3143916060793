






































import { IPopupButton } from '@/store/popup';
import Vue from 'vue';
export default Vue.extend({
    name: 'popup',
    data() {
        return {
            input: undefined as string | undefined,
        };
    },
    computed: {
        visible(): boolean {
            return this.$store.state.popup.visible;
        },
        title(): string | undefined {
            return this.$store.state.popup.title;
        },
        message(): string | undefined {
            return this.$store.state.popup.message;
        },
        label(): string | undefined {
            return this.$store.state.popup.label;
        },
        component(): any {
            return this.$store.state.popup.component;
        },
        buttons(): IPopupButton[] | undefined {
            return this.$store.state.popup.buttons;
        },
    },
    watch: {
        visible(n: boolean, o: boolean) {
            if (n == true && o == false) {
                this.input = undefined;
                if (this.label) {
                    this.$nextTick(() => {
                        (this.$refs.input as HTMLElement).focus();
                    });
                }
            }
        },
    },
    methods: {
        onClick(button: IPopupButton) {
            this.$store.dispatch('popup/hide');
            if (button && button.onClick) {
                button.onClick(this.input);
            }
        },
    },
});
