







import Vue from 'vue';

import ManagePlayers from '@/components/ManagePlayers.vue';

export default Vue.extend({
    components: {
        ManagePlayers,
    },
});
