import { Module } from 'vuex';

import { IUserLoginInput } from '@/models/input/userLoginInput';

import { apiGet, apiPost } from './api';
import { IUser } from '@/models/user';

interface IUserState {
    user: IUser | undefined;
}

const userState: IUserState = {
    user: undefined,
};

const module: Module<IUserState, any> = {
    namespaced: true,
    state: userState,
    getters: {
        loggedIn(state) {
            return state.user !== undefined;
        },
    },
    mutations: {
        setUser(state, user: IUser | undefined) {
            state.user = user;
        },
    },
    actions: {
        async login(store, login: IUserLoginInput): Promise<boolean> {
            const r = await apiPost('/user/login', {
                email: login.email,
                password: login.password,
            });
            if (r.ok) {
                const u = await r.json();
                store.commit('setUser', u);
            }
            return r.ok;
        },
        async logout(store) {
            const r = await apiGet('/user/logout');
            if (r.ok) {
                store.commit('setUser', undefined);
            }
            return r.ok;
        },
        async check(store) {
            if (store.getters.loggedIn) {
                return true;
            }
            const r = await apiGet('/user/check');
            if (r.ok) {
                const u = await r.json();
                store.commit('setUser', u);
            }
            return r.ok;
        },
        async emailExists(_store, email: string): Promise<boolean> {
            const r = await apiPost('/user/emailExists', { email });
            if (r.ok) {
                return (await r.json()) as boolean;
            }
            return false;
        },
    },
};

export default module;
