


















































































import Vue from 'vue';

import Countdown from '@/components/Countdown.vue';

import { IFlippableCard, IPlayerCards } from '@/models/card';
import { IGame } from '@/models/game';
import { IPlayerAnswer } from '@/models/player';

export default Vue.extend({
    name: 'card-reader',
    components: {
        Countdown,
    },
    data() {
        return {
            winAnswer: -1,
        };
    },
    computed: {
        gameId(): string {
            return this.$route.params.id;
        },
        isCzar(): boolean {
            if (this.$store.state.hub.cards) {
                return this.$store.state.hub.cards.isCzar;
            }
            return false;
        },
        readingAnwers(): IPlayerAnswer[] | null {
            return this.$store.state.hub.playerAnswers;
        },
        allRead(): boolean {
            if (this.$store.state.hub.playerAnswers) {
                return !(this.$store.state.hub
                    .playerAnswers as IPlayerAnswer[]).some(x =>
                    x.answers.some(y => y.faceUp === false),
                );
            }
            return false;
        },
        cards(): IPlayerCards | null {
            return this.$store.state.hub.cards;
        },
        serverWinner(): string {
            return this.$store.state.hub.winner;
        },
        serverWinAnswerId(): number {
            return this.$store.state.hub.winAnswerId;
        },
    },
    methods: {
        flipCardClick(pa: IPlayerAnswer, card: IFlippableCard) {
            if (this.allRead) {
                return;
            }

            if (this.isCzar) {
                if (pa.answers.length > 1) {
                    const readingOthers = this.readingAnwers
                        ?.filter(ra => ra.id !== pa.id)
                        .some(ra => {
                            const faceUp = ra.answers.filter(
                                a => a.faceUp === true,
                            );
                            if (faceUp) {
                                return (
                                    faceUp.length > 0 &&
                                    faceUp.length < ra.answers.length
                                );
                            }
                            return false;
                        });
                    if (!readingOthers) {
                        const firstDown = pa.answers.find(
                            a => a.faceUp === false,
                        );
                        if (firstDown && firstDown.id === card.id) {
                            this.setFaceUp(card);
                        }
                    }
                } else {
                    this.setFaceUp(card);
                }
            }
        },
        answerClick(pa: IPlayerAnswer) {
            if (this.allRead) {
                this.winAnswer = pa.id;
            }
        },
        setFaceUp(card: IFlippableCard) {
            card.faceUp = true;
            this.$store.dispatch('hubCzarReadCard', {
                gameId: this.gameId,
                card: card.id,
            });
        },
        async chooseWinnerClick() {
            if (this.winAnswer >= 0) {
                await this.$store.dispatch('hubChooseWinner', {
                    gameId: this.gameId,
                    winner: this.winAnswer,
                });
                this.winAnswer = -1;
            }
        },
        getWinningPlayer() {
            const p = (this.$store.state.hub.game as IGame).players.find(
                x => x.id === this.serverWinner,
            );
            return p ? p.name : null;
        },
        nextRoundClick() {
            this.$store.dispatch('hubNextRound', this.gameId);
        },
    },
});
