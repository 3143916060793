import { Module } from 'vuex';

export interface IPopupButton {
    label: string;
    class?: string;
    onClick?: (input: string | undefined) => void;
}

interface IPopupState {
    visible: boolean;
    title?: string;
    message?: string;
    label?: string;
    component?: any;
    buttons?: IPopupButton[];
}

export interface IPopupData {
    title: string;
    message: string;
    label?: string;
    component?: any;
    buttons?: IPopupButton[];
}

export interface IPopupResult {
    result: boolean;
    input?: string;
}

export const popupState: IPopupState = {
    visible: false,
    title: undefined,
    message: undefined,
    label: undefined,
    component: undefined,
    buttons: undefined,
};

export const module: Module<IPopupState, any> = {
    namespaced: true,
    state: popupState,
    mutations: {
        setShow(state, data: IPopupData) {
            state.title = data.title;
            state.message = data.message;
            state.label = data.label;
            state.component = data.component;
            state.buttons = data.buttons;
            state.visible = true;
        },
        setHide(state) {
            state.visible = false;
            state.title = undefined;
            state.message = undefined;
            state.label = undefined;
            state.component = undefined;
            state.buttons = undefined;
        },
    },
    actions: {
        show(context, data: IPopupData) {
            return new Promise(resolve => {
                if (!data.buttons) {
                    data.buttons = [
                        { label: 'Ok', onClick: () => resolve(true) },
                    ];
                }
                context.commit('setShow', data);
            });
        },
        confirm(context, data: IPopupData) {
            return new Promise(resolve => {
                if (!data.buttons) {
                    data.buttons = [
                        {
                            label: 'Yes',
                            class: 'green',
                            onClick: () => resolve(true),
                        },
                        {
                            label: 'No',
                            class: 'red',
                            onClick: () => resolve(false),
                        },
                    ];
                }
                context.commit('setShow', data);
            });
        },
        input(context, data: IPopupData) {
            return new Promise(resolve => {
                if (!data.buttons) {
                    data.buttons = [
                        {
                            label: 'OK',
                            class: 'green',
                            onClick: input =>
                                resolve({
                                    result: true,
                                    input,
                                } as IPopupResult),
                        },
                        {
                            label: 'Cancel',
                            class: 'red',
                            onClick: () =>
                                resolve({ result: false } as IPopupResult),
                        },
                    ];
                }
                context.commit('setShow', data);
            });
        },
        hide(context) {
            context.commit('setHide');
        },
    },
};

export default module;
