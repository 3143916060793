import { IPlayer } from './player';

export enum GameState {
    Setup,
    Ready,
    InRound,
    Reading,
    Finished,
}

enum RoundOrderEnum {
    Sequence,
    Winner,
}

export interface IGameSettings {
    cardsInHand: number;
    numRandos: number;
    pickOnesOnly: boolean;
    roundOrder: RoundOrderEnum;
    decks: number[];
    rules: IGameRules;
}

export interface IGame {
    displayId: string;
    state: GameState;
    players: IPlayer[];
    round: number;
    settings: IGameSettings;
    blackCardsLeft: number;
    whiteCardsLeft: number;
}

export interface IGameRules {
    gambling: boolean;
    lastResort: boolean;
    awesomeForDiscard: boolean;
    discardCzar: boolean;
    clueless: boolean;
}
