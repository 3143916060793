const jsonHdrs = {
    'Content-Type': 'application/json',
};

async function apiFetch(ep: string, method: string, body: any) {
    return await fetch(`/api${ep}`, {
        method,
        headers: jsonHdrs,
        body: JSON.stringify(body),
    });
}

export const apiGet = async (ep: string) => {
    return await fetch(`/api${ep}`);
};

export const apiPost = async (ep: string, body: any) => {
    return await apiFetch(ep, 'POST', body);
};

export const apiPut = async (ep: string, body: any) => {
    return await apiFetch(ep, 'PUT', body);
};

export const apiDelete = async (ep: string, body: any) => {
    return await apiFetch(ep, 'DELETE', body);
};
