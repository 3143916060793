















import Vue from 'vue';

import ButtonSpinner from '@/components/ButtonSpinner.vue';
import FormFields from '@/components/form/FormFields.vue';
import { FormBuilder, IFormField } from '@/components/form/form-builder';

import { apiPost } from '@/store/api';
import { IUserLoginInput } from '@/models/input/userLoginInput';

export default Vue.extend({
    components: {
        ButtonSpinner,
        FormFields,
    },
    data() {
        return {
            fields: [] as IFormField[],
            formData: {
                email: '',
                password: '',
            },
            info: '',
        };
    },
    mounted() {
        this.fields = this.getFormFields();
    },
    methods: {
        getFormFields() {
            return new FormBuilder()
                .addTextbox('e-mail', 'email')
                .addPassword('password', 'password')
                .build();
        },
        async loginClick() {
            if (!this.formData.email && !this.formData.password) {
                return;
            }
            const ok = await this.$store.dispatch('user/login', {
                email: this.formData.email,
                password: this.formData.password,
            } as IUserLoginInput);
            if (ok) {
                if (this.$route.query.from) {
                    const from = this.$route.query.from as string;
                    this.$router.push(from);
                }
            }
        },
        async forgotClick() {
            if (!this.formData.email) {
                return;
            }
            const r = await apiPost('/user/forgot', {
                email: this.formData.email,
            });
            if (r.ok) {
                this.info = 'reset password e-mail sent.';
            }
        },
    },
});
