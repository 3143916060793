import { Module } from 'vuex';

interface ICluelessState {
    dialog: number;
}

const cluelessState: ICluelessState = {
    dialog: 0,
};

const module: Module<ICluelessState, any> = {
    namespaced: true,
    state: cluelessState,
    mutations: {
        incrementDialog(state) {
            state.dialog = state.dialog + 1;
        },
    },
    actions: {
        show(context) {
            context.commit('incrementDialog');
        },
    },
};

export default module;
