






















import Vue from 'vue';

export default Vue.extend({
    name: 'game-login',
    data() {
        return {
            name: '',
        };
    },
    computed: {
        gameName(): string {
            return this.$store.state.game.name;
        },
    },
    methods: {
        async startGameClick() {
            if (this.name) {
                const r = await this.$store.dispatch('gameLogin', {
                    gameId: this.$route.params.id,
                    name: this.name,
                });
                if (r) {
                    this.$router.push(`/game/${this.$route.params.id}`);
                }
            }
        },
    },
});
