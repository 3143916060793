



























import Vue from 'vue';

import ButtonSpinner from '../ButtonSpinner.vue';

import { IFormField } from './form-builder';

export default Vue.extend({
    components: { ButtonSpinner },
    props: {
        field: Object,
        data: Object,
    },
    computed: {
        fieldTyped() {
            // computed fields are acting weird the props?
            const f0 = this.field as IFormField;
            return f0;
        },
        isButton() {
            const f0 = this.field as IFormField;
            return f0.type === 'button';
        },
        isReadonly() {
            const f0 = this.field as IFormField;
            return f0.readonly;
        },
        dataValue(): string {
            const f = this.field as IFormField;
            if (f.property) {
                const v = this.data[f.property];
                return v;
            }
            return '';
        },
    },
    methods: {
        fieldClick() {
            if (this.isButton || this.isReadonly) {
                return;
            }
            const input = this.$refs['input'] as HTMLElement;
            if (input) {
                input.focus();
            }
        },
        async buttonClick() {
            if (this.fieldTyped.action) {
                await this.fieldTyped.action();
            }
        },
        inputHandle(v: string) {
            if (this.fieldTyped.property) {
                // eslint-disable-next-line vue/no-mutating-props
                this.data[this.fieldTyped.property] = v;
            }
        },
    },
});
