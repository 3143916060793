var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"game-leaderboard"},[_c('leaderboard')],1),(_vm.isSetup)?_c('div',[_vm._v(" Waiting for host to finishing setting up the game... ")]):(_vm.isReady)?_c('div',[(_vm.isHost)?_c('div',[(_vm.hasPlayers)?_c('button',{staticClass:"button",on:{"click":_vm.startGameClick}},[_vm._v(" Start Game ")]):_c('div',[_vm._v("Need at least 2 players to start the game.")])]):_c('div',[_vm._v("Waiting for host to start the game...")])]):(_vm.isInRound)?_c('div',[(_vm.cards)?[(_vm.cards.isCzar)?_c('div',[_c('div',{staticClass:"flex-cards"},[_c('div',{staticClass:"card black"},[_vm._v(" "+_vm._s(_vm.cards.blackCard.text)+" ")]),_c('div',{staticClass:"card actions"},[(
                                _vm.gameRules.discardCzar &&
                                !_vm.currentPlayer.czarDiscarded &&
                                !_vm.rules.discardCzar
                            )?_c('div',[_c('input',{staticClass:"button",attrs:{"type":"button","value":"Discard Czar"},on:{"click":_vm.discardCzarClick}})]):_vm._e(),(_vm.rules.discardCzar)?[(_vm.dcCards.length > 0)?_c('div',[_c('input',{staticClass:"button green",attrs:{"type":"button","value":"Discard Card"},on:{"click":_vm.discardCzarConfirmClick}})]):_vm._e(),_c('div',[_c('input',{staticClass:"button red",attrs:{"type":"button","value":"Cancel Discard"},on:{"click":_vm.discardCzarCancelClick}})])]:_vm._e()],2),_c('div',[_vm._v("Waiting for players...")])]),(_vm.rules.discardCzar)?_c('div',[_c('hand',{attrs:{"cards":_vm.cards.whiteCards,"selected":_vm.dcCards,"showInfo":true,"readonly":false,"info":"Discard 1 Card"},on:{"card-click":_vm.discardCzarCardClick}})],1):_vm._e()]):_c('div',[_c('div',{staticClass:"flex-cards"},[_c('div',{staticClass:"card black"},[_vm._v(" "+_vm._s(_vm.cards.blackCard.text)+" ")]),_vm._l((_vm.selectedCards),function(card){return _c('div',_vm._g({key:card.id,staticClass:"card white",class:{ selectable: !_vm.isAnswered }},
                            _vm.isAnswered
                                ? {}
                                : { click: function () { return _vm.selectedCardClick(card); } }
                        ),[_vm._v(" "+_vm._s(card.text)+" ")])}),_vm._l((_vm.numPlaceHolders),function(ph){return _c('div',{key:ph,staticClass:"card placeholder"},[_vm._v(" "+_vm._s(ph + _vm.selectedCards.length)+" ")])}),(_vm.rules.isGambling)?_c('div',{staticClass:"flex-cards"},[_vm._l((_vm.gambleCards),function(card){return _c('div',_vm._g({key:card.id,staticClass:"card white",class:{ selectable: !_vm.isAnswered }},
                                _vm.isAnswered
                                    ? {}
                                    : {
                                          click: function () { return _vm.selectedCardClick(card); },
                                      }
                            ),[_vm._v(" "+_vm._s(card.text)+" ")])}),_vm._l((_vm.gamblePlaceHolders),function(ph){return _c('div',{key:ph,staticClass:"card placeholder"},[_vm._v(" "+_vm._s(ph + _vm.gambleCards.length)+" ")])})],2):_vm._e(),(_vm.isAnswered)?_c('div',[_vm._v(" Waiting for all players to submit... ")]):_c('div',{staticClass:"card actions"},[(_vm.rules.discarding)?[(_vm.discard.cards.length > 0)?_c('div',[_c('input',{staticClass:"button green",attrs:{"type":"button","value":("Discard " + (_vm.discard.cards.length) + " Card" + (_vm.discard.cards.length > 1 ? 's' : ''))},on:{"click":_vm.discardConfirmClick}})]):_vm._e(),_c('div',[_c('input',{staticClass:"button red",attrs:{"type":"button","value":"Cancel Discard"},on:{"click":_vm.discardCancelClick}})])]:[(_vm.gameRules.lastResort)?_c('div',[_c('input',{staticClass:"button",attrs:{"type":"button","value":"Last Resort"},on:{"click":_vm.lastResortClick}})]):_vm._e(),(
                                    _vm.gameRules.gambling && _vm.currentScore > 0
                                )?_c('div',[_c('input',{staticClass:"button",class:{ red: _vm.rules.isGambling },attrs:{"type":"button","value":_vm.rules.isGambling
                                            ? 'Cancel Gamble'
                                            : 'Gamble'},on:{"click":_vm.gambleClick}})]):_vm._e(),(
                                    _vm.gameRules.awesomeForDiscard &&
                                    _vm.currentScore > 0
                                )?_c('div',[_c('input',{staticClass:"button",attrs:{"type":"button","value":"Discard White Cards"},on:{"click":_vm.discardWhiteClick}})]):_vm._e(),(
                                    _vm.gameRules.clueless &&
                                    !_vm.currentPlayer.cluelessDiscarded
                                )?_c('div',[_c('input',{staticClass:"button",attrs:{"type":"button","value":"Clueless"},on:{"click":_vm.cluelessClick}})]):_vm._e(),(_vm.hasAllPicks)?_c('div',[_c('input',{staticClass:"button green",attrs:{"type":"button","value":"Submit"},on:{"click":_vm.submitCardsClick}})]):_vm._e()]],2)],2),_c('hand',{attrs:{"cards":_vm.cards.whiteCards,"selected":_vm.testSelected,"readonly":_vm.isAnswered,"showInfo":_vm.rules.discarding,"info":_vm.discard.msg},on:{"card-click":_vm.cardInHandClick}})],1)]:_c('div',[_vm._v("Waiting for cards...")])],2):(_vm.isReading)?_c('div',[_c('card-reader')],1):(_vm.isFinished)?_c('div',[_vm._v("Game finished.")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }