







import Vue from 'vue';

export default Vue.extend({
    name: 'home',
    computed: {
        name(): string {
            return this.$store.state.game.name;
        },
    },
    methods: {
        async newGame() {
            const g = await this.$store.dispatch('newGame');
            if (g) {
                this.$router.push(`/game/${g.displayId}/setup`);
            }
        },
    },
});
