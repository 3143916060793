









































































































































































































































import Vue from 'vue';

import CardReader from '@/components/CardReader.vue';
import Hand from '@/components/Hand.vue';
import Leaderboard from '@/components/Leaderboard.vue';

import { IGame, GameState, IGameSettings, IGameRules } from '@/models/game';
import { IPlayerCards, ICard } from '../models/card';
import { IPlayer } from '../models/player';
import { IPopupData } from '@/store/popup';

export default Vue.extend({
    name: 'game-home',
    components: {
        CardReader,
        Hand,
        Leaderboard,
    },
    data() {
        return {
            rules: {
                isGambling: false,
                discarding: false,
                discardCzar: false,
            },
            discard: {
                cards: [] as ICard[],
                msg: '',
                num: 0,
                action: undefined as (() => Promise<void>) | undefined,
            },
            selectedCards: [] as ICard[],
            gambleCards: [] as ICard[],
            dcCards: [] as ICard[],
        };
    },
    computed: {
        gameId(): string {
            return this.$route.params.id;
        },
        gameRules(): IGameRules {
            return (this.$store.getters.settings as IGameSettings)?.rules;
        },
        isHost(): boolean {
            return this.currentPlayer.isHost;
        },
        hasPlayers(): boolean {
            if (this.$store.state.hub.game) {
                return (this.$store.state.hub.game as IGame).players.length > 1;
            }
            return false;
        },
        isSetup() {
            return this.$store.getters.gameState === GameState.Setup;
        },
        isReady() {
            return this.$store.getters.gameState === GameState.Ready;
        },
        isInRound() {
            return this.$store.getters.gameState === GameState.InRound;
        },
        isReading() {
            return this.$store.getters.gameState === GameState.Reading;
        },
        isFinished() {
            return this.$store.getters.gameState === GameState.Finished;
        },
        gameState() {
            return this.$store.getters.gameState;
        },
        playerAnswers(): IPlayer[] {
            if (this.$store.state.hub.game) {
                return this.$store.state.hub.game.players.filter(
                    (p: IPlayer) => !p.isCzar,
                );
            }
            return [];
        },
        cards(): IPlayerCards | null {
            return this.$store.state.hub.cards;
        },
        picks(): number {
            return this.cards ? this.cards.blackCard.picks : 1;
        },
        numPlaceHolders(): number {
            return this.picks - this.selectedCards.length;
        },
        gamblePlaceHolders(): number {
            return this.picks - this.gambleCards.length;
        },
        hasAllPicks(): boolean {
            const gamble =
                !this.rules.isGambling || this.gambleCards.length >= this.picks;
            return this.selectedCards.length >= this.picks && gamble;
        },
        isAnswered(): boolean {
            return this.$store.getters.isAnswered;
        },
        currentPlayer(): IPlayer {
            return this.$store.getters.currentPlayer;
        },
        currentScore(): number {
            return this.currentPlayer ? this.currentPlayer.score : 0;
        },
        testSelected(): ICard[] {
            if (this.rules.discarding) {
                return this.discard.cards;
            }
            const cards = [] as ICard[];
            if (this.rules.isGambling && this.gambleCards.length > 0) {
                cards.push(...this.gambleCards);
            }
            if (this.selectedCards.length > 0) {
                cards.push(...this.selectedCards);
            }
            return cards;
        },
        hubConnected(): boolean {
            return this.$store.state.hub.connected;
        },
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            if (this.hubConnected) return;

            const game: IGame = await this.$store.dispatch(
                'loadGame',
                this.gameId,
            );

            if (!game) {
                this.$router.push('/');
                return;
            }

            this.$store.commit('setNextRound', this.nextRound);

            const r: boolean = await this.$store.dispatch('gameCheck');
            if (r) {
                await this.$store.dispatch('initHub');
            } else {
                this.$router.push(`${this.$route.path}/login`);
            }
        },
        nextRound() {
            this.selectedCards = [];
            this.gambleCards = [];
            this.dcCards = [];
        },
        async startGameClick() {
            await this.$store.dispatch('hubStartGame', this.gameId);
        },
        cardInHandClick(card: ICard) {
            if (this.rules.discarding) {
                const idx = this.discard.cards.indexOf(card);
                if (idx >= 0) {
                    this.discard.cards.splice(idx, 1);
                } else {
                    if (this.discard.cards.length < this.discard.num)
                        this.discard.cards.push(card);
                }
                return;
            }
            const idx = this.selectedCards.indexOf(card);
            const gidx = this.gambleCards.indexOf(card);

            if (idx >= 0) {
                this.selectedCards.splice(idx, 1);
            } else if (gidx >= 0) {
                this.gambleCards.splice(idx, 1);
            } else {
                if (
                    this.picks === 1 &&
                    this.selectedCards.length === 1 &&
                    !this.rules.isGambling
                ) {
                    this.selectedCards.splice(0, 1);
                    this.selectedCards.push(card);
                } else if (this.selectedCards.length < this.picks) {
                    this.selectedCards.push(card);
                } else if (
                    this.rules.isGambling &&
                    this.gambleCards.length < this.picks
                ) {
                    this.gambleCards.push(card);
                }
            }
        },
        selectedCardClick(card: ICard) {
            const idx = this.selectedCards.indexOf(card);
            const gidx = this.gambleCards.indexOf(card);
            if (idx >= 0) {
                this.selectedCards.splice(idx, 1);
            } else if (gidx >= 0) {
                this.gambleCards.splice(idx, 1);
            }
        },
        submitCardsClick() {
            const answers = this.selectedCards.map(x => x.id);
            let gambles: number[] = [];
            if (this.rules.isGambling) {
                gambles = this.gambleCards.map(x => x.id);
            }
            this.$store.dispatch('hubSetAnswer', {
                gameId: this.gameId,
                answers,
                gambles,
            });
            this.resetRules();
        },
        gambleClick() {
            if (this.currentScore === 0) {
                return;
            }
            this.rules.isGambling = !this.rules.isGambling;
        },
        resetRules() {
            this.rules.isGambling = false;
        },
        lastResortClick() {
            this.$store
                .dispatch('popup/confirm', {
                    title: 'Confirm Last Resort',
                    message: 'Are you sure you want to use the last resort?',
                } as IPopupData)
                .then((result: boolean) => {
                    if (result) {
                        this.$store.dispatch('hubLastResort');
                    }
                });
        },
        discardWhiteClick() {
            this.rules.discarding = !this.rules.discarding;

            if (this.rules.discarding) {
                this.discard = {
                    cards: [],
                    num: 5,
                    msg: 'For 1 Awesome Point, pick up to 5 cards to discard.',
                    action: async () => {
                        const discards = this.discard.cards.map(x => x.id);
                        await this.$store.dispatch(
                            'hubAwesomeForDiscard',
                            discards,
                        );
                        this.resetDiscard();
                    },
                };
            } else {
                this.resetDiscard();
            }
        },
        resetDiscard() {
            this.rules.discarding = false;
            this.discard = {
                cards: [],
                msg: '',
                num: 0,
                action: undefined,
            };
        },
        discardCancelClick() {
            this.rules.discarding = false;
            this.resetDiscard();
        },
        async discardConfirmClick() {
            if (this.discard.cards.length == 0) {
                await this.$store.dispatch('popup/show', {
                    title: 'No Discards',
                    message: 'You have no cards selected to discard.',
                } as IPopupData);
                return;
            }
            if (this.discard.action) {
                await this.discard.action();
            }
        },
        async discardCzarConfirmClick() {
            if (this.dcCards.length == 0) {
                await this.$store.dispatch('popup/show', {
                    title: 'No Discards',
                    message: 'You have no cards selected to discard.',
                } as IPopupData);
                return;
            }
            const discard = this.dcCards[0].id;
            await this.$store.dispatch('hubDiscardCzar', discard);
            this.discardCzarCancelClick();
        },
        discardCzarClick() {
            this.rules.discardCzar = !this.rules.discardCzar;

            if (!this.rules.discardCzar) {
                this.discardCzarCancelClick();
            }
        },
        discardCzarCardClick(card: ICard) {
            if (this.dcCards.length == 1) {
                this.dcCards.splice(0, 1);
                this.dcCards.push(card);
            } else if (this.dcCards.length == 0) {
                this.dcCards.push(card);
            }
        },
        discardCzarCancelClick() {
            this.rules.discardCzar = false;
            this.dcCards = [];
        },
        cluelessClick() {
            this.rules.discarding = !this.rules.discarding;

            if (this.rules.discarding) {
                this.discard = {
                    cards: [],
                    num: 1,
                    msg: 'Discard 1 card, and admit you are clueless!',
                    action: async () => {
                        const discard = this.discard.cards[0].id;
                        await this.$store.dispatch('hubClueless', discard);
                        this.resetDiscard();
                    },
                };
            } else {
                this.resetDiscard();
            }
        },
    },
});
