







import Vue from 'vue';
import Popup from '@/components/Popup.vue';

export default Vue.extend({
    components: {
        Popup,
    },
});
