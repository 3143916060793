




























import Vue from 'vue';

export default Vue.extend({
    name: 'progress-ring',
    data() {
        return {
            id: 0,
            progress: 0,
        };
    },
    props: {
        stroke: {
            type: Number,
            default: 2,
        },
        radius: {
            type: Number,
            default: 9,
        },
        iconColor: {
            type: String,
            default: 'currentColor',
        },
    },
    computed: {
        normalizedRadius(): number {
            return this.radius - this.stroke * 2;
        },
        circumference(): number {
            return this.normalizedRadius * 2 * Math.PI;
        },
        offset(): number {
            return (
                this.circumference - (this.progress / 100) * this.circumference
            );
        },
    },
    mounted() {
        this.id = window.setInterval(() => {
            this.progress++;
            if (this.progress >= 100) {
                window.clearInterval(this.id);
            }
        }, 300);
    },
    destroyed() {
        window.clearInterval(this.id);
    },
});
