





























import Vue from 'vue';

import { IPlayer } from '@/models/player';
import IconBase from './IconBase.vue';
import IconX from '@/icons/IconX.vue';
import { IPopupData } from '@/store/popup';

export default Vue.extend({
    components: { IconBase, IconX },
    computed: {
        players(): IPlayer[] {
            if (this.$store.state.hub.game) {
                return this.$store.state.hub.game.players;
            }
            return [];
        },
        hasPlayers(): boolean {
            return this.players.length > 0;
        },
        currentPlayer(): IPlayer | undefined {
            return this.$store.getters.currentPlayer;
        },
    },
    methods: {
        async removePlayerClick(player: IPlayer) {
            const r: boolean = await this.$store.dispatch('popup/confirm', {
                title: 'Confirm Remove Player',
                message: `Are you sure you want to remove ${player.name}?`,
            } as IPopupData);
            if (r) {
                await this.$store.dispatch('hubRemovePlayer', player.id);
            }
        },
    },
});
