import { Module } from 'vuex';

import { IDeckSummary } from '@/models/deckSummary';

interface ISetupState {
    decks: IDeckSummary[] | null;
}

export const setupState: ISetupState = {
    decks: null,
};

export const module: Module<ISetupState, any> = {
    state: setupState,
    getters: {},
    mutations: {
        setDecks(state, decks) {
            state.decks = decks;
        },
    },
    actions: {
        async loadDecks(context) {
            if (context.state.decks) {
                return context.state.decks;
            }

            const r = await fetch('/api/game/availabledecks');
            if (r.ok) {
                const decks = await r.json();
                if (decks) {
                    context.commit('setDecks', decks);
                }
            }
            return null;
        },
    },
};

export default module;
