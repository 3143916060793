









import Vue from 'vue';

export default Vue.extend({
    computed: {
        gameId(): string {
            return this.$route.params.id;
        },
        gameLink(): string {
            return `/game/${this.gameId}`;
        },
    },
});
