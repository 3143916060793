







import Vue from 'vue';

import NavHeader from '@/components/NavHeader.vue';

export default Vue.extend({
    components: { NavHeader },
});
