import { Module } from 'vuex';

import { IGame } from '@/models/game';
import { apiGet, apiPost } from '@/store/api';

interface IGameState {
    game: IGame | null;
    name: string;
}

export const gameState: IGameState = {
    game: null,
    name: 'Cards Against Hilarity',
};

export const module: Module<IGameState, any> = {
    state: gameState,
    getters: {
        settings: state => {
            if (state.game) {
                return state.game.settings;
            }
            return null;
        },
    },
    mutations: {
        setGame(state, game) {
            state.game = game;
        },
        setSettings(state, settings) {
            if (state.game) {
                state.game.settings = settings;
            }
        },
    },
    actions: {
        async newGame(context) {
            const r = await apiGet('/game/new');
            if (r.ok) {
                const game = await r.json();
                context.commit('setGame', game);
                return game;
            }
            return null;
        },
        async loadGame(context, id) {
            if (context.state.game) {
                return context.state.game;
            }

            const r = await apiGet(`/game/get/${id}`);
            if (r.ok) {
                const game = await r.json();
                context.commit('setGame', game);
                return game;
            }

            return null;
        },
        async readyGame(_context, settings) {
            return await apiPost('/game/ready', settings);
        },
        async isHost() {
            const r = await apiGet('/game/ishost');
            if (r.ok) {
                return await r.json();
            }
            return false;
        },
        async gameLogin(_context, input) {
            const r = await apiPost('/game/login', input);
            return r.ok;
        },
        async gameLogout() {
            const r = await apiGet('/game/logout');
            return r.ok;
        },
        async gameCheck() {
            const r = await apiGet('/game/check');
            return r.ok;
        },
    },
};

export default module;
