import { Module } from 'vuex';

import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';

import router from '@/router';

import { IGame } from '@/models/game';
import { ICard, IPlayerCards } from '@/models/card';
import { IPlayerAnswer } from '@/models/answer';
import { IPlayer } from '@/models/player';
import { IWaitTimer } from '@/models/waitTimer';

export interface ICluelessPlayer {
    player: string;
    card: ICard;
}

interface IHubState {
    connection: HubConnection | null;
    connected: boolean;
    game: IGame | null;
    cards: IPlayerCards | null;
    playerAnswers: IPlayerAnswer[] | null;
    winner: string;
    winAnswerId: number;
    currentPlayer: IPlayer | null;
    idleTimer: IWaitTimer | null;
    nextRound: (() => void) | undefined;
    cluelessPlayers: ICluelessPlayer[];
}

export const hubState: IHubState = {
    connection: null,
    connected: false,
    game: null,
    cards: null,
    playerAnswers: null,
    winner: '',
    winAnswerId: -1,
    currentPlayer: null,
    idleTimer: null,
    nextRound: undefined,
    cluelessPlayers: [],
};

export const module: Module<IHubState, any> = {
    state: hubState,
    getters: {
        gameState: state => {
            if (state.game) {
                return state.game.state;
            }
            return null;
        },
        isAnswered: state => {
            if (state.game && state.currentPlayer) {
                const p = state.game.players.find(
                    x => x.id === state.currentPlayer?.id,
                );
                if (p) {
                    return p.answered;
                }
            }
            return false;
        },
        currentPlayer: state => {
            if (state.game && state.currentPlayer) {
                return state.game.players.find(
                    x => x.id === state.currentPlayer?.id,
                );
            }
            return undefined;
        },
        hasCluelessPlayers: state => {
            return state.cluelessPlayers.length > 0;
        },
    },
    mutations: {
        setConnection(state, conn) {
            state.connection = conn;
        },
        setConnected(state, connected) {
            state.connected = connected;
        },
        setGameState(state, game) {
            state.game = game;
        },
        setCards(state, cards) {
            state.cards = cards;
        },
        setPlayerAnswers(state, data) {
            state.playerAnswers = data;
        },
        setWinner(state, winner) {
            state.winner = winner;
        },
        setWinAnswerId(state, id) {
            state.winAnswerId = id;
        },
        setCurrentPlayer(state, player) {
            state.currentPlayer = player;
        },
        setIdleTimer(state, timer) {
            state.idleTimer = timer;
        },
        updatePlayer(state, player: IPlayer) {
            if (state.game) {
                let p = state.game.players.find(pp => pp.id === player.id);
                if (p) {
                    p = player;
                }
            }
        },
        setNextRound(state, nextRound) {
            state.nextRound = nextRound;
        },
        addCluelessPlayer(state, data: ICluelessPlayer) {
            state.cluelessPlayers.push(data);
        },
        removeCluelessPlayer(state) {
            state.cluelessPlayers.shift();
        },
    },
    actions: {
        initHub(context) {
            return new Promise(resolve => {
                const conn = new HubConnectionBuilder()
                    .withUrl('/cahHub')
                    .build();

                context.commit('setConnection', conn);

                conn.on('UpdateGameState', (game: IGame) => {
                    context.commit('setGameState', game);
                });

                conn.on('CurrentPlayer', (p: IPlayer) => {
                    context.commit('setCurrentPlayer', p);
                });

                conn.on('SendCards', (cards: IPlayerCards) => {
                    context.commit('setCards', cards);
                });

                conn.on('SendAnswers', (data: IPlayerAnswer[]) => {
                    context.commit('setPlayerAnswers', data);
                });

                conn.on('SendWinner', (answerId: number, winner: string) => {
                    context.commit('setWinner', winner);
                    context.commit('setWinAnswerId', answerId);
                });

                conn.on('NextRound', () => {
                    context.commit('setPlayerAnswers', null);
                    context.commit('setWinner', '');
                    context.commit('setWinAnswerId', -1);
                    if (context.state.nextRound) {
                        context.state.nextRound();
                    }
                });

                conn.on('StartTimer', (msg: string, secs: number) => {
                    context.commit('setIdleTimer', {
                        message: msg,
                        seconds: secs,
                    } as IWaitTimer);
                });

                conn.on('CancelTimer', () => {
                    context.commit('setIdleTimer', null);
                });

                conn.on('StartPlayerTimer', (secs: number) => {
                    if (secs > 0) {
                        // x
                    }
                });

                conn.on('CancelPlayerTimer', () => {
                    // x
                });

                conn.on('CluelessPlayer', (player: string, card: ICard) => {
                    context.commit('addCluelessPlayer', {
                        player,
                        card,
                    } as ICluelessPlayer);
                });

                conn.on('PlayerRemoved', async () => {
                    await conn.stop();
                    await context.dispatch('gameLogout');
                    await router.push('/');
                });

                conn.onclose(() => {
                    context.commit('setConnected', false);
                });

                conn.start().then(() => {
                    context.commit('setConnected', true);
                    resolve(null);
                });
            });
        },
        async hubStartGame(context, gameId) {
            await context.state.connection?.invoke('StartGame', gameId);
        },
        async hubEndGame(context) {
            const gameId = context.state.game?.displayId;
            await context.state.connection?.invoke('EndGame', gameId);
        },
        async hubSetAnswer(
            context,
            data: { gameId: string; answers: number[]; gambles: number[] },
        ) {
            await context.state.connection?.invoke(
                'SetAnswer',
                data.gameId,
                data.answers,
                data.gambles,
            );
        },
        async hubCzarReadCard(context, data: { gameId: string; card: number }) {
            await context.state.connection?.invoke(
                'ReadCard',
                data.gameId,
                data.card,
            );
        },
        async hubChooseWinner(
            context,
            data: { gameId: string; winner: number },
        ) {
            await context.state.connection?.invoke(
                'ChooseWinner',
                data.gameId,
                data.winner,
            );
        },
        async hubNextRound(context, gameId: string) {
            await context.state.connection?.invoke('NextRound', gameId);
        },
        async hubLastResort(context) {
            const gameId = context.state.game?.displayId;
            await context.state.connection?.invoke('LastResort', gameId);
        },
        async hubAwesomeForDiscard(context, cards: number[]) {
            const gameId = context.state.game?.displayId;
            await context.state.connection?.invoke(
                'AwesomeForDiscard',
                gameId,
                cards,
            );
        },
        async hubDiscardCzar(context, card: number) {
            const gameId = context.state.game?.displayId;
            await context.state.connection?.invoke('DiscardCzar', gameId, card);
        },
        async hubClueless(context, card: number) {
            const gameId = context.state.game?.displayId;
            await context.state.connection?.invoke('Clueless', gameId, card);
        },
        getNextCluelessPlayer(context) {
            if (context.state.cluelessPlayers.length > 0) {
                const cp = context.state.cluelessPlayers[0];
                context.commit('removeCluelessPlayer');
                return cp;
            }
            return undefined;
        },
        async hubLeaveGame(context) {
            const gameId = context.state.game?.displayId;
            await context.state.connection?.invoke('LeaveGame', gameId);
            await context.state.connection?.stop();
            return context.dispatch('gameLogout');
        },
        async hubRenamePlayer(context, name) {
            await context.state.connection?.invoke('RenamePlayer', name);
        },
        async hubRemovePlayer(context, playerId) {
            const gameId = context.state.game?.displayId;
            await context.state.connection?.invoke(
                'RemovePlayer',
                gameId,
                playerId,
            );
        },
    },
};

export default module;
