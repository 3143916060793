











import Vue from 'vue';

import { IMenuItem } from '@/models/menuItem';

export default Vue.extend({
    props: {
        item: Object,
    },
    computed: {
        itemTyped(): IMenuItem | undefined {
            return this.item ? (this.item as IMenuItem) : undefined;
        },
        isHidden(): boolean {
            return this.itemTyped && this.itemTyped.hidden
                ? this.itemTyped.hidden()
                : false;
        },
    },
    methods: {
        menuItemClick(item: IMenuItem) {
            this.$emit('item-click', item);
        },
    },
});
