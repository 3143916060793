































import Vue from 'vue';

import IconBase from './IconBase.vue';
import ProgressRing from './ProgressRing.vue';
import Checkmark from '@/icons/Checkmark.vue';
import Crown from '@/icons/Crown.vue';
import IconX from '@/icons/IconX.vue';

import { IPlayer } from '@/models/player';

export default Vue.extend({
    name: 'leaderboard',
    components: {
        IconBase,
        Checkmark,
        Crown,
        IconX,
        ProgressRing,
    },
    computed: {
        players(): IPlayer[] {
            if (this.$store.state.hub.game) {
                return this.$store.state.hub.game.players;
            }
            return [];
        },
        hasPlayers(): boolean {
            return this.players.length > 0;
        },
    },
});
