

















































































import Vue from 'vue';

import DeckList, { DeckListRef } from '@/components/DeckList.vue';
import SetupRule from '@/components/SetupRule.vue';

import { IDeckSummary } from '../models/deckSummary';
import { IGameRules, IGameSettings } from '@/models/game';

export default Vue.extend({
    name: 'game-setup',
    components: {
        DeckList,
        SetupRule,
    },
    data() {
        return {
            name: '',
            settings: {} as IGameSettings,
        };
    },
    computed: {
        gameName(): string {
            return this.$store.state.game.name;
        },
        decks(): IDeckSummary[] {
            return this.$store.state.setup.decks;
        },
        rules(): IGameRules {
            return this.storeSettings?.rules;
        },
        deckTypes(): string[] {
            const types: string[] = [];
            this.decks?.forEach(d => {
                if (d.type && !types.includes(d.type)) {
                    types.push(d.type);
                }
            });
            return types;
        },
        storeSettings(): IGameSettings {
            return this.$store.getters.settings;
        },
        deckList(): DeckListRef {
            return this.$refs.deckList as DeckListRef;
        },
    },
    async mounted() {
        await this.$store.dispatch('loadGame', this.$route.params.id);
        if (this.storeSettings) {
            this.settings = this.storeSettings;
        }
        await this.$store.dispatch('loadDecks');
    },
    methods: {
        async startGameClick() {
            if (!this.name) {
                return;
            }
            const selected = this.deckList.getSelectedDecks();
            if (!selected || selected.length === 0) {
                return;
            }
            this.settings.decks = selected;
            await this.$store.dispatch('readyGame', {
                name: this.name,
                gameId: this.$route.params.id,
                settings: this.settings,
            });
            this.$router.push(`/game/${this.$route.params.id}`);
        },
    },
});
