


































import Vue from 'vue';

import IconBase from '@/components/IconBase.vue';
import IconMenu from '@/icons/IconMenu.vue';
import MainMenu, { MainMenuRef } from '@/components/MainMenu.vue';

import { IGame } from '../models/game';
import { IPlayer } from '../models/player';

export default Vue.extend({
    name: 'nav-header',
    components: {
        IconBase,
        IconMenu,
        MainMenu,
    },
    computed: {
        currentGame(): IGame | null {
            return this.$store.state.hub.game;
        },
        currentRound(): number {
            return this.currentGame ? this.currentGame.round : 0;
        },
        blackCardsLeft(): number {
            return this.currentGame ? this.currentGame.blackCardsLeft : 0;
        },
        whiteCardsLeft(): number {
            return this.currentGame ? this.currentGame.whiteCardsLeft : 0;
        },
        players(): IPlayer[] {
            if (this.$store.state.hub.game) {
                return this.$store.state.hub.game.players;
            }
            return [];
        },
        hasCluelessPlayers(): boolean {
            return this.$store.getters.hasCluelessPlayers;
        },
        menuRef(): MainMenuRef {
            return this.$refs.menu as MainMenuRef;
        },
        loggedIn(): boolean {
            return this.$store.getters['user/loggedIn'];
        },
    },
    methods: {
        showCluelessPlayersClick() {
            this.$store.dispatch('clueless/show');
        },
        menuClick() {
            this.menuRef.toggleMenu();
        },
        logoutClick() {
            this.$store.dispatch('user/logout');
        },
    },
});
