
















import Vue from 'vue';

import FormFields from '@/components/form/FormFields.vue';
import ButtonSpinner from '@/components/ButtonSpinner.vue';

import { apiGet, apiPost } from '@/store/api';
import { IUser } from '@/models/user';
import { FormBuilder, IFormField } from '@/components/form/form-builder';
import { IPopupData } from '@/store/popup';

export default Vue.extend({
    components: {
        FormFields,
        ButtonSpinner,
    },
    data() {
        return {
            fields: [] as IFormField[],
            formData: {
                email: '',
                npw: '',
                cpw: '',
            },
            error: 'loading...' as string | undefined,
        };
    },
    computed: {
        token() {
            return this.$route.params['token'];
        },
    },
    async mounted() {
        if (!this.token) {
            return;
        }

        const r = await apiGet(`/user/tokenInfo/${this.token}`);
        if (r.ok) {
            const u: IUser = await r.json();
            if (u) {
                this.formData.email = u.email;
                this.fields = this.getFormFields();
                this.error = undefined;
            }
        } else {
            this.error = 'invalid link.';
        }
    },
    methods: {
        getFormFields() {
            return new FormBuilder()
                .addTextbox('email', 'email')
                .addPassword('new password', 'npw')
                .addPassword('confirm password', 'cpw')
                .build();
        },
        async resetPasswordClick() {
            if (this.formData.npw !== this.formData.cpw) {
                await this.$store.dispatch('popup/show', {
                    title: 'Invalid Password',
                    message: "The passwords entered don't match.",
                } as IPopupData);
                return;
            }

            const r = await apiPost('/user/reset', {
                token: this.token,
                email: this.formData.email,
                password: this.formData.npw,
            });
            if (r.ok) {
                // x
            }
        },
    },
});
