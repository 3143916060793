var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('countdown'),_c('div',{staticClass:"flex-cards"},[_c('div',{staticClass:"card black"},[_vm._v(" "+_vm._s(_vm.cards.blackCard.text)+" ")]),(_vm.allRead)?[_vm._l((_vm.readingAnwers),function(pa){return _c('div',_vm._g({key:pa.id,staticClass:"flex-cards answer",class:{
                    selectable: !_vm.serverWinner && _vm.isCzar,
                    winner:
                        _vm.winAnswer === pa.id || _vm.serverWinAnswerId === pa.id,
                }},
                    !_vm.serverWinner && _vm.isCzar
                        ? { click: function () { return _vm.answerClick(pa); } }
                        : {}
                ),[_vm._l((pa.answers),function(card){return _c('div',{key:card.id,staticClass:"card white"},[(card.faceUp)?[_vm._v(_vm._s(card.text))]:_vm._e()],2)}),(
                        _vm.winAnswer === pa.id || _vm.serverWinAnswerId === pa.id
                    )?_c('div',{staticClass:"selected-overlay"},[(_vm.serverWinner)?_c('div',{staticClass:"player"},[_vm._v(" "+_vm._s(_vm.getWinningPlayer())+" ")]):_vm._e()]):_vm._e()],2)}),(_vm.isCzar)?_c('div',{staticClass:"card actions"},[(_vm.serverWinner)?_c('div',[_c('input',{staticClass:"button",attrs:{"type":"button","value":"Next Round"},on:{"click":_vm.nextRoundClick}})]):(_vm.winAnswer >= 0)?_c('div',[_c('input',{staticClass:"button",attrs:{"type":"button","value":"Choose Winner"},on:{"click":_vm.chooseWinnerClick}})]):_c('div',[_vm._v("Select the winner.")])]):_vm._e()]:_vm._l((_vm.readingAnwers),function(pa){return _c('div',{key:pa.id,staticClass:"flex-cards answer"},_vm._l((pa.answers),function(card){return _c('div',{key:card.id,staticClass:"card white",class:{ selectable: _vm.isCzar },on:{"click":function($event){return _vm.flipCardClick(pa, card)}}},[(card.faceUp)?[_vm._v(_vm._s(card.text))]:_vm._e()],2)}),0)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }