import Vue from 'vue';
import Vuex from 'vuex';

import setup from './setup';
import game from './game';
import hub from './hub';
import popup from './popup';
import user from './user';
import clueless from './clueless';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        setup,
        game,
        hub,
        popup,
        user,
        clueless,
    },
});
