
























import { ICard } from '@/models/card';
import { ICluelessPlayer } from '@/store/hub';
import Vue from 'vue';

const cluelessPopup = Vue.extend({
    name: 'clueless-popup',
    data() {
        return {
            show: false,
            player: undefined as string | undefined,
            card: undefined as ICard | undefined,
        };
    },
    computed: {
        itemsLeft(): number {
            return this.$store.state.hub.cluelessPlayers.length;
        },
        title(): string {
            const post = this.itemsLeft > 0 ? ` (${this.itemsLeft} more)` : '';
            return `${this.player} is Clueless!${post}`;
        },
        message(): string {
            return `${this.player} doesn't know what this card means. Ha ha!`;
        },
        buttonText(): string {
            return this.itemsLeft == 0 ? 'Close' : 'Next';
        },
    },
    methods: {
        async showDialog() {
            if (this.show == false) {
                await this.getNext();
            }
        },
        async nextClick() {
            await this.getNext();
        },
        closeClick() {
            this.show = false;
        },
        async getNext() {
            const cp = (await this.$store.dispatch(
                'getNextCluelessPlayer',
            )) as ICluelessPlayer;

            if (cp) {
                this.show = true;
                this.player = cp.player;
                this.card = cp.card;
            } else {
                this.show = false;
            }
        },
    },
});

export type CluelessPopupRef = InstanceType<typeof cluelessPopup>;
export default cluelessPopup;
