













import Vue from 'vue';
export default Vue.extend({
    name: 'setup-rule',
    props: {
        value: Boolean,
        label: String,
    },
});
