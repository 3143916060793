
















import Vue from 'vue';

export default Vue.extend({
    name: 'IconBase',
    props: {
        iconName: {
            type: String,
            default: 'box',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        iconColor: {
            type: String,
            default: 'currentColor',
        },
    },
});
