







import Vue from 'vue';

import { apiGet } from '@/store/api';

export default Vue.extend({
    data() {
        return {
            error: 'loading...' as string | undefined,
        };
    },
    computed: {
        token() {
            return this.$route.params['token'];
        },
    },
    async mounted() {
        if (!this.token) {
            return;
        }

        const r = await apiGet(`/user/verify/${this.token}`);
        if (r.ok) {
            this.error = 'e-mail verified!';
        } else {
            this.error = 'invalid link.';
        }
    },
    methods: {},
});
