


































































import { IDeckSummary } from '@/models/deckSummary';
import Vue from 'vue';

const deckList = Vue.extend({
    name: 'deck-list',
    mounted() {
        this.setSelectedDecks();
    },
    props: {
        decks: Array,
        deckTypes: Array,
        initialDecks: Array,
    },
    computed: {
        decksTyped(): IDeckSummary[] {
            return this.decks as IDeckSummary[];
        },
        mainDecks(): IDeckSummary[] {
            return this.decksTyped?.filter(d => !d.type);
        },
        initialDecksTyped(): number[] {
            return this.initialDecks as number[];
        },
    },
    methods: {
        decksByType(dt: string) {
            return this.decksTyped?.filter(d => d.type === dt);
        },
        toggleDeck(dt: string) {
            const items = document.getElementsByClassName(`deck ${dt}`);
            for (const item of items) {
                const i = item as HTMLElement;
                i.hidden = !i.hidden;
            }
        },
        setSelectedDecks() {
            if (this.initialDecksTyped) {
                this.initialDecksTyped.forEach(d => {
                    const e = document.querySelector(
                        `input.cb-decks.deck-${d}`,
                    );
                    if (e) {
                        (e as HTMLInputElement).checked = true;
                    }
                });
            }
        },
        getSelectedDecks() {
            const decks = document.querySelectorAll('input.cb-decks');
            const selected: number[] = [];
            decks.forEach(d => {
                if ((d as HTMLInputElement).checked) {
                    const v = (d as HTMLDataElement).dataset.value;
                    if (v) {
                        const id = parseInt(v, 10);
                        selected.push(id);
                    }
                }
            });
            return selected;
        },
    },
});

export type DeckListRef = InstanceType<typeof deckList>;
export default deckList;
