import Vue from 'vue';
import Router from 'vue-router';

import Home from './views/Home.vue';
import Game from './views/Game.vue';
import GameHome from './views/GameHome.vue';
import GameLogin from './views/GameLogin.vue';
import GameSetup from './views/GameSetup.vue';
import GameManage from './views/GameManage.vue';
import GameManageHome from './views/GameManageHome.vue';
import GameManagePlayers from './views/GameManagePlayers.vue';

import User from './views/User.vue';
import UserLogin from './views/UserLogin.vue';
import UserProfile from './views/UserProfile.vue';
import UserRegister from './views/UserRegister.vue';
import UserReset from './views/UserReset.vue';
import UserVerify from './views/UserVerify.vue';

import store from './store';

import { IPlayer } from './models/player';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/user',
            component: User,
            children: [
                {
                    path: 'login',
                    component: UserLogin,
                },
                {
                    path: 'register',
                    component: UserRegister,
                },
                {
                    path: 'profile',
                    component: UserProfile,
                    meta: { auth: true },
                },
                {
                    path: 'reset/:token',
                    component: UserReset,
                },
                {
                    path: 'verify/:token',
                    component: UserVerify,
                },
            ],
        },
        {
            path: '/game/:id',
            component: Game,
            children: [
                {
                    path: '',
                    component: GameHome,
                },
                {
                    path: 'setup',
                    component: GameSetup,
                },
                {
                    path: 'login',
                    component: GameLogin,
                },
                {
                    path: 'manage',
                    component: GameManage,
                    beforeEnter: (to, from, next) => {
                        const p: IPlayer = store.getters.currentPlayer;
                        if (p && p.isHost) {
                            next();
                        } else {
                            next(`/game/${to.params.id}`);
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'game-manage-home',
                            component: GameManageHome,
                        },
                        {
                            path: 'players',
                            component: GameManagePlayers,
                        },
                        {
                            path: '*',
                            redirect: { name: 'game-manage-home' },
                        },
                    ],
                },
            ],
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(x => x.meta.auth)) {
        const ok = await store.dispatch('user/check');
        if (ok) {
            next();
        } else {
            next({ path: '/user/login', query: { from: to.fullPath } });
        }
    } else {
        next();
    }
});

export default router;
